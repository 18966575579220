import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { EditDeviceTypesPage } from "./containers/editDeviceTypesPage";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={EditDeviceTypesPage} />
    </Switch>
  );
};
