function prefixZero(n: number): string {
  return n < 10 ? `0${n}` : String(n);
}

export function secondsToFormattedHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 60 / 60);
  const hourText = hours === 1 ? "hour" : "hours";
  const minutes = Math.floor((seconds / 60) % 60);
  const minuteText = minutes === 1 ? "minute" : "minutes";
  const formattedSeconds = Math.floor(seconds % 60);
  const secondsText = formattedSeconds === 1 ? "second" : "seconds";
  // if under an hour - do not show hours
  if (seconds < 60 * 60) {
    // if there's no minutes left, leave them out
    if (minutes > 0) {
      return `${minutes} ${minuteText} ${formattedSeconds} ${secondsText}`;
    } else {
      return `${formattedSeconds} ${secondsText}`;
    }
  } else {
    // If there's no minutes left, but a full hour, show seconds instead
    if (minutes > 0) {
      return `${hours} ${hourText} ${minutes} ${minuteText}`;
    } else {
      return `${hours} ${hourText} ${formattedSeconds} ${secondsText}`;
    }
  }
}

export function timestampToFormattedString(timestamp: string): string {
  const unixTime = Date.parse(timestamp);
  const date = new Date(unixTime);
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${prefixZero(hours)}:${prefixZero(minutes)}:${prefixZero(
    seconds
  )} ${day}/${month}/${year}`;
}
