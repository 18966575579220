import {
  createEntityAdapter,
  EntityState,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { IDeviceType } from "../backendApi/types/devices";
import { IDeviceTypeRequestBody } from "../backendApi/types/requestBodies";
import {
  getDeviceTypes,
  postDeviceType,
  putDeviceType,
  deleteDeviceType,
} from "./backendApi";
import { RootState } from "../../store/types";

const sliceName = "deviceTypes";

// --- Thunks
export const fetchAllDeviceTypes = createAsyncThunk(
  `${sliceName}/fetchAll`,
  async (thunkAPI) => {
    const response = await getDeviceTypes();
    return response.data;
  }
);

interface ICreateDeviceTypeArgs {
  deviceTypeRequestBody: IDeviceTypeRequestBody;
  onStopLoading: () => void;
}
export const createDeviceType = createAsyncThunk(
  `${sliceName}/createDeviceType`,
  async (
    { deviceTypeRequestBody, onStopLoading }: ICreateDeviceTypeArgs,
    thunkAPI
  ) => {
    const response = await postDeviceType(deviceTypeRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface IUpdateDeviceTypeArgs {
  deviceTypeId: number;
  deviceTypeRequestBody: IDeviceTypeRequestBody;
  onStopLoading: () => void;
}
export const updateDeviceType = createAsyncThunk(
  `${sliceName}/updateDeviceType`,
  async (
    {
      deviceTypeId,
      deviceTypeRequestBody,
      onStopLoading,
    }: IUpdateDeviceTypeArgs,
    thunkAPI
  ) => {
    const response = await putDeviceType(deviceTypeId, deviceTypeRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface IDeleteDeviceTypeByIdArgs {
  deviceTypeId: number;
  onStopLoading: () => void;
}
export const deleteDeviceTypeById = createAsyncThunk(
  `${sliceName}/deleteDeviceTypeById`,
  async (
    { deviceTypeId, onStopLoading }: IDeleteDeviceTypeByIdArgs,
    thunkAPI
  ) => {
    await deleteDeviceType(deviceTypeId);
    onStopLoading();
  }
);

// --- Setting up slice
export interface DeviceTypesState extends EntityState<IDeviceType> {
  fetching: boolean;
}
const deviceTypesAdapter = createEntityAdapter<IDeviceType>();
const initialState: DeviceTypesState = deviceTypesAdapter.getInitialState({
  fetching: false,
});

const deviceTypesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDeviceTypes.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(fetchAllDeviceTypes.fulfilled, (state, action) => {
      deviceTypesAdapter.upsertMany(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(fetchAllDeviceTypes.rejected, (state, action) => {
      state.fetching = false;
    });

    // Create device type
    builder.addCase(createDeviceType.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(createDeviceType.fulfilled, (state, action) => {
      deviceTypesAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(createDeviceType.rejected, (state, action) => {
      state.fetching = false;
    });

    // Update device type
    builder.addCase(updateDeviceType.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(updateDeviceType.fulfilled, (state, action) => {
      deviceTypesAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(updateDeviceType.rejected, (state, action) => {
      state.fetching = false;
    });

    // Delete device type
    builder.addCase(deleteDeviceTypeById.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(deleteDeviceTypeById.fulfilled, (state, action) => {
      const deviceTypeId = action.meta.arg.deviceTypeId;
      deviceTypesAdapter.removeOne(state, deviceTypeId);
      state.fetching = false;
    });
    builder.addCase(deleteDeviceTypeById.rejected, (state, action) => {
      state.fetching = false;
    });
  },
});

// --- Selectors
function selectDeviceTypesState(state: RootState): DeviceTypesState {
  return state.deviceTypes;
}

export function selectFetching(state: RootState): boolean {
  return selectDeviceTypesState(state).fetching;
}

export const {
  selectAll: selectAllDeviceTypes,
} = deviceTypesAdapter.getSelectors((state: RootState) => state.deviceTypes);

export default deviceTypesSlice.reducer;
