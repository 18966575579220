import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IDeviceType } from "../../../shared/backendApi/types/devices";
import { IDeviceTypeRequestBody } from "../../../shared/backendApi/types/requestBodies";
import {
  selectAllDeviceTypes,
  fetchAllDeviceTypes,
  createDeviceType,
  deleteDeviceTypeById,
  selectDeviceTypesFetching,
  updateDeviceType,
} from "../../../shared/deviceTypes";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

interface IDeviceTypeRow {
  id: number;
  name: string;
  minTemp: number;
  maxTemp: number;
  isDeleteable: boolean;
}
function createDeviceTypeRows(deviceTypes: IDeviceType[]): IDeviceTypeRow[] {
  return deviceTypes.map((deviceType) => ({
    id: deviceType.id,
    name: deviceType.name,
    minTemp: deviceType.minTemp,
    maxTemp: deviceType.maxTemp,
    isDeleteable: deviceType.isDeleteable,
  }));
}

function rowToRequestBody(row: IDeviceTypeRow): IDeviceTypeRequestBody {
  return {
    name: row.name,
    minTemp: Number(row.minTemp),
    maxTemp: Number(row.maxTemp),
  };
}

function isNonNanNumber(n: number): boolean {
  return typeof n === "number" && !isNaN(n);
}

export const EditDeviceTypesPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const deviceTypes = useSelector(selectAllDeviceTypes);
  const isFetching = useSelector(selectDeviceTypesFetching);

  useEffect(() => {
    dispatch(fetchAllDeviceTypes());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Device types</title>
      </Helmet>
      <AppDrawerMenuLayout>
        <div className={classes.tableWrapper}>
          <MaterialTable
            isLoading={isFetching}
            columns={[
              {
                title: "Name",
                field: "name",
                validate: (rowData) =>
                  rowData.name ? !!rowData.name.trim() : false,
              },
              {
                title: "Min Temp",
                field: "minTemp",
                validate: (rowData) => isNonNanNumber(Number(rowData.minTemp)),
              },
              {
                title: "Max Temp",
                field: "maxTemp",
                validate: (rowData) => isNonNanNumber(Number(rowData.maxTemp)),
              },
            ]}
            data={createDeviceTypeRows(deviceTypes)}
            options={{
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 40, 80, 100],
            }}
            editable={{
              isDeletable: (row) => row.isDeleteable,
              onRowAdd: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    createDeviceType({
                      deviceTypeRequestBody: rowToRequestBody(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowUpdate: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    updateDeviceType({
                      deviceTypeId: newRow.id,
                      deviceTypeRequestBody: rowToRequestBody(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowDelete: (row) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    deleteDeviceTypeById({
                      deviceTypeId: row.id,
                      onStopLoading: resolve,
                    })
                  );
                }),
            }}
          />
        </div>
      </AppDrawerMenuLayout>
    </>
  );
};
