import {
  createEntityAdapter,
  EntityState,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../../store/types";
import { IUser } from "../backendApi/types/user";
import { getUsers, deleteUser, putUser, postUser } from "./backendApi";
import { IUserRequestBody } from "../backendApi/types/requestBodies";

const sliceName = "users";

// --- Thunks
export const fetchAllUsers = createAsyncThunk(
  `${sliceName}/fetchAll`,
  async (thunkAPI) => {
    const response = await getUsers();
    return response.data;
  }
);

interface createUserArgs {
  userRequestBody: IUserRequestBody;
  onStopLoading: () => void;
}
export const createUser = createAsyncThunk(
  `${sliceName}/createUser`,
  async ({ userRequestBody, onStopLoading }: createUserArgs, thunkApi) => {
    const response = await postUser(userRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface updateUserArgs extends createUserArgs {
  userId: number;
}
export const updateUser = createAsyncThunk(
  `${sliceName}/updateUser`,
  async (
    { userId, userRequestBody, onStopLoading }: updateUserArgs,
    thunkApi
  ) => {
    const response = await putUser(userId, userRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface deleteUserArgs {
  userId: number;
  onStopLoading: () => void;
}
export const deleteUserById = createAsyncThunk(
  `${sliceName}/deleteUser`,
  async ({ userId, onStopLoading }: deleteUserArgs, thunkApi) => {
    await deleteUser(userId);
    onStopLoading();
  }
);

// --- Setting up slice
export interface UsersState extends EntityState<IUser> {
  fetching: boolean;
}

const usersAdapter = createEntityAdapter<IUser>();
const initialState: UsersState = usersAdapter.getInitialState({
  fetching: false,
});

const usersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch all users
    builder.addCase(fetchAllUsers.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      usersAdapter.upsertMany(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.fetching = false;
    });

    // Create User
    builder.addCase(createUser.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.fetching = false;
    });

    // Update user
    builder.addCase(updateUser.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.fetching = false;
    });

    // Delete User
    builder.addCase(deleteUserById.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(deleteUserById.fulfilled, (state, action) => {
      const userId = action.meta.arg.userId;
      usersAdapter.removeOne(state, userId);
      state.fetching = false;
    });
    builder.addCase(deleteUserById.rejected, (state, action) => {
      state.fetching = false;
    });
  },
});

// --- Selectors
function selectUsersState(state: RootState): UsersState {
  return state.users;
}

export function selectFetching(state: RootState): boolean {
  return selectUsersState(state).fetching;
}

export const { selectAll: selectAllUsers } = usersAdapter.getSelectors(
  selectUsersState
);

export default usersSlice.reducer;
