//TODO: THIS IS NOT A PRETTY FILE - it could probably use some refactoring
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import MaterialTable from "material-table";
import {
  fetchAllDeviceTypes,
  selectAllDeviceTypes,
  selectDeviceTypesFetching,
} from "../../../shared/deviceTypes";
import {
  useInsertIfAdmin,
  useIsUserAdmin,
} from "../../../shared/authentication";
import { DisplayOrientation } from "../../../shared/backendApi/types/devices";
import { IDeviceRequestBody } from "../../../shared/backendApi/types/requestBodies";
import {
  selectAllDevices,
  fetchAllDevices,
  IDeviceEntity,
  deleteDeviceById,
  createDevice,
  updateDevice,
  selectFetching,
} from "../devicesSlice";
import {
  selectCustomersFetching,
  useCustomersLookupObj,
} from "../../../shared/customers";

import {
  selectAllCampaigns,
  fetchAllCampaigns,
  selectCampaignsFetching,
} from "../../../shared/campaigns";

interface IDeviceRow {
  id: number;
  serialNumber: string | undefined;
  macAddress: string;
  deviceType: number;
  campaign?: number;
  location: string;
  customer?: number;
  isDeleteable: boolean;
  displayOrientation?: DisplayOrientation;
}

// Formats the list of devices to Device rows that can be shown by the table
function createDeviceRows(devices: IDeviceEntity[]): IDeviceRow[] {
  return devices.map((device) => ({
    id: device.id,
    serialNumber: device.serialNumber ? device.serialNumber : "",
    macAddress: device.macAddress,
    deviceType: device.type.id,
    campaign: device.campaign ? device.campaign.id : undefined,
    location: device.location,
    customer: device.customer?.id,
    isDeleteable: device.isDeleteable,
    displayOrientation:
      typeof device.displayOrientation === "number"
        ? device.displayOrientation
        : undefined,
  }));
}

// Takes a deviceRow and turn it into a deviceRequestBody
function createDeviceRequestBodyFromRow(row: IDeviceRow): IDeviceRequestBody {
  return {
    serialNumber: row.serialNumber,
    macAddress: row.macAddress,
    campaign: Number(row.campaign),
    location: row.location,
    type: Number(row.deviceType),
    displayOrientation:
      row.displayOrientation !== undefined
        ? Number(row.displayOrientation)
        : undefined,
    customer: Number(row.customer),
  };
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

export const EditDevicesPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const devices = useSelector(selectAllDevices);
  const deviceTypes = useSelector(selectAllDeviceTypes);
  const campaigns = useSelector(selectAllCampaigns);

  const insertIfAdmin = useInsertIfAdmin();
  const isUserAdmin = useIsUserAdmin();
  console.log(isUserAdmin);

  const customersLookupObj = useCustomersLookupObj(true);

  // Setup loading variables
  const [isDevicesFetching, isCampaignsFetching, isDeviceTypesFetching] = [
    useSelector(selectFetching),
    useSelector(selectCampaignsFetching),
    useSelector(selectDeviceTypesFetching),
    useSelector(selectCustomersFetching),
  ];

  //TODO: I don't think there is a reason to use state for this anyways
  const [deviceTypesLookup, setDeviceTypesLookup] = useState<object>({});
  const [campaignsLookup, setCampaignsLookup] = useState<object>({});

  useEffect(
    function () {
      dispatch(fetchAllDevices());
      dispatch(fetchAllDeviceTypes());
      dispatch(fetchAllCampaigns());
    },
    [dispatch]
  );

  // Create lookup object for device Types
  useEffect(
    function () {
      const lookupObj = {};
      deviceTypes.forEach((deviceType) => {
        Object.assign(lookupObj, { [deviceType.id]: deviceType.name });
      });
      setDeviceTypesLookup(lookupObj);
    },
    [setDeviceTypesLookup, deviceTypes]
  );

  // Create lookup object for campaigns
  useEffect(
    function () {
      const lookupObj = {};
      // Set the customers we can lookup, based on the retrievedCampaigns
      campaigns.forEach((campaign) => {
        Object.assign(lookupObj, {
          [campaign.id]: `${campaign.name} ${
            isUserAdmin
              ? campaign.customer
                ? `(${campaign.customer.name})`
                : "(No customer)"
              : ""
          }`,
        });
      });
      // Add an option to have no campaign
      Object.assign(lookupObj, { undefined: "None" });
      setCampaignsLookup(lookupObj);
    },
    [setCampaignsLookup, campaigns, isUserAdmin]
  );

  return (
    <>
      <Helmet>
        <title>Devices</title>
      </Helmet>
      <AppDrawerMenuLayout>
        <div className={classes.tableWrapper}>
          <MaterialTable
            isLoading={
              isDeviceTypesFetching || isCampaignsFetching || isDevicesFetching
            }
            columns={[
              {
                title: "Serial Number",
                field: "serialNumber",
                editable: isUserAdmin ? "always" : "never",
              },
              ...insertIfAdmin({
                title: "MAC address",
                field: "macAddress",
                type: "string",
                validate: (rowData: any) => !!rowData.macAddress,
              }),
              {
                title: "Device type",
                field: "deviceType",
                lookup: deviceTypesLookup,
                validate: (rowData) => !!rowData.deviceType,
                editable: isUserAdmin ? "always" : "never",
              },
              {
                title: "Location",
                field: "location",
                type: "string",
                validate: (rowData) => !!rowData.location,
              },
              {
                title: "Campaign",
                field: "campaign",
                lookup: campaignsLookup,
              },
              {
                title: "Orientation",
                field: "displayOrientation",
                lookup: {
                  undefined: "Determined by campaign",
                  [DisplayOrientation.LANDSCAPE]: "Landscape",
                  [DisplayOrientation.PORTRAIT]: "Portrait",
                },
              },
              ...insertIfAdmin({
                title: "Customer",
                field: "customer",
                lookup: customersLookupObj,
              }),
            ]}
            data={createDeviceRows(devices)}
            options={{
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 40, 80, 100],
            }}
            editable={{
              isDeletable: (rowData) => rowData.isDeleteable,
              ...insertIfAdmin(
                {
                  onRowAdd: (newRow: any) =>
                    new Promise((resolve, reject) => {
                      dispatch(
                        createDevice({
                          deviceRequestBody: createDeviceRequestBodyFromRow(
                            newRow
                          ),
                          onStopLoading: resolve,
                        })
                      );
                    }),
                },
                true
              ),
              onRowUpdate: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    updateDevice({
                      deviceId: newRow.id,
                      deviceRequestBody: createDeviceRequestBodyFromRow(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
              ...insertIfAdmin(
                {
                  onRowDelete: (oldData: any) =>
                    new Promise((resolve, reject) => {
                      dispatch(
                        deleteDeviceById({
                          deviceId: oldData.id,
                          onStopLoading: resolve,
                        })
                      );
                    }),
                },
                true
              ),
            }}
          />
        </div>
      </AppDrawerMenuLayout>
    </>
  );
};
