import { AxiosResponse } from "axios";
import instance from "../../axios";
import { IDevice } from "../../shared/backendApi/types/devices";
import { IDeviceRequestBody } from "../../shared/backendApi/types/requestBodies";

export async function getDevices(): Promise<AxiosResponse<IDevice[]>> {
  const response = await instance.get("devices");
  return response;
}

export async function getDevice(id: number): Promise<AxiosResponse<IDevice>> {
  const response = await instance.get(`devices/${id}`);
  return response;
}

export async function deleteDevice(id: number): Promise<AxiosResponse<void>> {
  const response = await instance.delete(`devices/${id}`);
  return response;
}

export async function postDevice(
  deviceRequestBody: IDeviceRequestBody
): Promise<AxiosResponse<IDevice>> {
  const response = await instance.post(`devices`, deviceRequestBody);
  return response;
}

export async function putDevice(
  id: number,
  deviceRequestBody: IDeviceRequestBody
): Promise<AxiosResponse<IDevice>> {
  const response = await instance.put(`devices/${id}`, deviceRequestBody);
  return response;
}
