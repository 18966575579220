import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import logo from "../../../shared/media/stibo-interactive-logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  useRedirectToIfAuthenticated,
  loginUser,
  selectFetchingErrors,
  selectFetchingStatus,
  FetchingError,
  FetchingStatus,
} from "../../../shared/authentication";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: "100px",
    width: "100px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  emailField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  button: {
    width: "100%",
    height: "48px",
    maxWidth: "236px",
    marginTop: theme.spacing(4),
    background: "linear-gradient(78.5deg, #0082CC 0%, #33B4FF 100%);",
  },
  buttonDisabled: {
    background: "none",
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    marginTop: theme.spacing(1),
  },
}));

export const LoginPage: React.FunctionComponent = () => {
  useRedirectToIfAuthenticated("/devices/monitor");

  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchingErrors: FetchingError[] = useSelector(selectFetchingErrors);
  const fetchingStatus: FetchingStatus = useSelector(selectFetchingStatus);
  const credentialError = fetchingErrors.includes(
    FetchingError.wrongCredentials
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(!email || !password);

  useEffect(
    function () {
      setIsDisabled(!email || !password);
    },
    [email, password]
  );
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={11}>
        <Paper className={classes.box}>
          <img
            src={logo}
            className={classes.logo}
            alt="Stibo Interactive Logo"
          />
          <Typography variant="h4" component="h1">
            <b>Stibo Interactive Login</b>
          </Typography>

          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(loginUser({ email, password }));
            }}
          >
            <TextField
              error={credentialError}
              className={classes.emailField}
              type="email"
              fullWidth={true}
              label="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value.replace(/ /g, ""))}
            />
            <TextField
              error={credentialError}
              fullWidth={true}
              label="password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value.replace(/ /g, ""))}
            />
            {fetchingErrors.length !== 0 ? (
              <Typography color="error" className={classes.errorMessage}>
                {credentialError
                  ? "No user found for this email/password"
                  : "An unknown error occured 🤯"}
              </Typography>
            ) : undefined}
            <Button
              classes={{
                root: classes.button,
                disabled: classes.buttonDisabled,
              }}
              variant="contained"
              color="primary"
              disabled={isDisabled}
              type="submit"
              disableElevation
            >
              {fetchingStatus === FetchingStatus.idle ? (
                "Login"
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Button>
          </form>
        </Paper>
        <Typography
          classes={{ root: classes.text }}
          variant="body2"
          color="textSecondary"
        >
          If you have forgotten your credentials, please contact your
          administrator.
        </Typography>
      </Grid>
    </Grid>
  );
};
