import { AxiosRequestConfig } from "axios";
import devices from "./devices.json";
import { IDevice } from "../types/devices";

function setTimestamp(device: IDevice) {
  if (device.status.isOnline && !!device.latestCheckin) {
    return {
      ...device,
      latestCheckin: {
        ...device.latestCheckin,
        timestamp: new Date(
          Date.now() - 60 * Math.random() * 6000
        ).toUTCString(),
      },
    };
  }
  return device;
}

export default function (config: AxiosRequestConfig): AxiosRequestConfig {
  const url = config.url as string;
  // The response to be sent back
  const response = {
    data: undefined as any,
    status: 200,
    statusText: "OK",
    headers: { "content-type": "text/plain; charset=utf-8" },
    config,
    request: {},
  };
  console.warn("Mocking call to:", url);
  if (/^devices\/*$/.test(url)) {
    const modifiedDevices = (devices as IDevice[]).map(setTimestamp);
    response.data = modifiedDevices;
  } else if (/^devices\/*\d+\/*$/.test(url)) {
    const id = Number(url.split("/")[1]);
    const device = devices.find((device) => device.id === id);
    response.data = setTimestamp(device as IDevice);
    if (!device) {
      response.status = 400;
    }
  } else {
    response.status = 400;
    response.statusText = "Not Found";
  }

  // Use an adapter to stop the request, and apply the modified response data
  config.adapter = function (config) {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => resolve(response), 3000);
    });
  };

  return config;
}
