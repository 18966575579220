import React, { useState } from "react";
import {
  makeStyles,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  Menu,
  MenuItem as MUIMenuItem,
} from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import {
  Web as WebIcon,
  Visibility as VisibilityIcon,
  Devices as DevicesIcon,
  Mood as MoodIcon,
  Tv as TvIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import tinyLogo from "../../../shared/media/stibo-interactive-logo-tiny.png";
import { useSelector } from "react-redux";
import { selectActiveUser, dumbLogOut } from "../../authentication";
import { useHistory } from "react-router-dom";
import { useInsertIfAdmin } from "../../authentication";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#202020",
    width: "240px",
    height: "100vh",
  },
  marginBelow: { marginBottom: theme.spacing(2) },
  logoBox: {
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  logoText: {
    lineHeight: 1.1,
    marginLeft: theme.spacing(2),
  },
  logoutBox: {
    position: "absolute",
    bottom: theme.spacing(2),
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

interface MenuItemProps {
  icon: React.ReactNode;
  text: string;
  link: string;
  className?: string;
}
const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  icon,
  text,
  link,
  className,
}) => {
  const history = useHistory();
  const isActive = history.location.pathname === link;

  return (
    <ListItem
      selected={isActive}
      className={className}
      button
      onClick={() => history.push(link)}
    >
      <ListItemIcon style={{ minWidth: "48px", marginLeft: "8px" }}>
        {icon}
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

interface AppDrawerMenuProps {
  className?: string;
  open?: boolean;
}

// If the provided name is
function limitFullName(firstName: string, lastName: string) {
  const fullName = `${firstName} ${lastName}`;
  if (fullName.length > 25) {
    return fullName.substring(0, 25) + "...";
  }
  return fullName;
}

export const AppDrawerMenu: React.FunctionComponent<AppDrawerMenuProps> = ({
  className,
  open = true,
}) => {
  const insertIfAdmin = useInsertIfAdmin();
  const classes = useStyles();
  const activeUser = useSelector(selectActiveUser);
  const fullName = activeUser
    ? limitFullName(activeUser.firstName, activeUser.lastName)
    : "";
  const initials = activeUser
    ? `${activeUser?.firstName[0]}${activeUser?.lastName[0]}`
    : "";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      classes={{
        root: className ? className : undefined,
        paper: classes.root,
      }}
      elevation={0}
      variant="persistent"
      open={open}
    >
      <div className={classes.logoBox}>
        <img
          src={tinyLogo}
          width="40px"
          height="40px"
          alt="Stibo Interactive Logo"
        />
        <div>
          <Typography
            className={classes.logoText}
            style={{ lineHeight: 1 }}
            variant="body2"
          >
            Stibo Interactive
          </Typography>
          <Typography
            className={classes.logoText}
            style={{ lineHeight: 1 }}
            variant="body1"
          >
            <b>Screen Admin</b>
          </Typography>
        </div>
      </div>

      <MenuItem
        className={classes.marginBelow}
        icon={<VisibilityIcon />}
        text="Monitor devices"
        link="/devices/monitor"
      />
      <MenuItem icon={<TvIcon />} text="Devices" link="/devices" />
      <MenuItem icon={<WebIcon />} text="Campaigns" link="/campaigns" />

      {insertIfAdmin(
        <MenuItem icon={<MoodIcon />} text="Customers" link="/customers" />,
        true
      )}
      {insertIfAdmin(
        <MenuItem
          icon={<DevicesIcon />}
          text="Device types"
          link="/device-types"
        />,
        true
      )}
      {insertIfAdmin(
        <MenuItem icon={<PeopleIcon />} text="Users" link="/users" />,
        true
      )}
      <ListItem
        className={classes.logoutBox}
        button
        onClick={handleUserMenuClick}
      >
        <ListItemIcon>
          <Avatar className={classes.avatar}>{initials}</Avatar>
        </ListItemIcon>
        <ListItemText>{fullName}</ListItemText>
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
      >
        <MUIMenuItem onClick={() => dumbLogOut("/authentication")}>
          Logout
        </MUIMenuItem>
      </Menu>
    </Drawer>
  );
};
