import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { fetchAllDevices, selectAllDevices } from "../devicesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, makeStyles, Button, Theme } from "@material-ui/core";
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@material-ui/icons";
import { DeviceCard } from "../components/deviceCard";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import { useLocation } from "react-router";

const gridSpacing = 2;
const useStyles = makeStyles<Theme, boolean>((theme) => ({
  root: {},
  gridContainer: {
    paddingTop: theme.spacing(gridSpacing),
    paddingBottom: theme.spacing(gridSpacing) / 2,
    flexGrow: 1,
  },
  gridItem: {
    maxWidth: (open) => (open ? 528 : 528 / 2),
  },
  fullScreenButton: {
    marginTop: theme.spacing(2),
    float: "right",
  },
}));

export const DeviceMonitor: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const [open, setOpen] = useState<boolean>(
    queryParams.get("fullscreen") !== "true"
  );
  // Sort by is Online, after that sort by customer
  const devices = useSelector(selectAllDevices)
    .sort((a, b) => (a.campaign?.id === b.campaign?.id ? -1 : 1))
    .sort((a, b) => (a.status.isOnline ? -1 : 1));
  const classes = useStyles(open);

  // Fetch all devices on mounts
  useEffect(() => {
    dispatch(fetchAllDevices());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Monitor Devices</title>
      </Helmet>
      <AppDrawerMenuLayout open={open}>
        <Container className={classes.root} maxWidth="xl">
          <Button
            className={classes.fullScreenButton}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <>
                <FullscreenIcon /> Fullscreen
              </>
            ) : (
              <>
                {" "}
                <FullscreenExitIcon /> Exit Fullscreen
              </>
            )}
          </Button>
          <Grid
            container
            className={`${classes.gridContainer}`}
            spacing={gridSpacing}
            justify="center"
          >
            {devices.map((device: any) => (
              <Grid
                key={device.id}
                item
                xs={12}
                lg={6}
                xl={4}
                className={classes.gridItem}
              >
                <DeviceCard device={device} className={""} imageOnly={!open} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </AppDrawerMenuLayout>
    </>
  );
};
