import axios from "axios";
import {
  tokenInterceptor,
  unauthenticatedRequestInterceptor,
} from "./shared/authentication/interceptors";
import mockInterceptor from "./shared/backendApi/mock/mockInterceptor";
import config from "./config/config.json";

const instance = axios.create({
  withCredentials: true,
  baseURL: config["apiEndpoint"],
});

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return unauthenticatedRequestInterceptor(error);
  }
);

if (config.useMock) {
  instance.interceptors.request.use(mockInterceptor);
}

export default instance;
