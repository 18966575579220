import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CircularProgress,
  CardMedia,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { IDevice } from "../../../shared/backendApi/types/devices";
import { PowerIndicator } from "./powerIndicator";
import { TemperatureIndicator } from "./temperatureIndicator";
import { InfoText } from "./infoText";
import {
  secondsToFormattedHoursMinutes,
  timestampToFormattedString,
  countdownTo,
} from "../../../shared/time";
import { UpArrowIcon } from "../../../shared/icons";
import {
  TouchApp as TouchAppIcon,
  Web as WebIcon,
  Mood as MoodIcon,
  Public as PublicIcon,
} from "@material-ui/icons";
import { fetchDeviceById, IDeviceEntity } from "../devicesSlice";
import { useInsertIfAdmin } from "../../../shared/authentication";

interface StylesProps {
  disabled: boolean;
  imageOnly: boolean;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: ({ imageOnly }: StylesProps) => (imageOnly ? 440 : 453),
    maxWidth: ({ imageOnly }: StylesProps) =>
      imageOnly ? "calc(528px /2)" : "528px",
    justifyContent: "space-between",
  },
  screenshot: ({ disabled }: StylesProps) => ({
    height: "100%",
    backgroundSize: "contain",
    backgroundColor: theme.palette.background.default,
    filter: disabled ? "grayscale(100%)" : "",
  }),
  column: {
    width: ({ imageOnly }: StylesProps) => (imageOnly ? "100%" : "50%"),
    height: "100%",
  },
  left: {
    maxWidth: "291px",
  },
  right: {
    padding: theme.spacing(1),
    display: ({ imageOnly }: StylesProps) => (imageOnly ? "none" : "initial"),
  },
  topMargin: {
    marginTop: theme.spacing(3),
  },
  refreshingText: {
    display: "flex",
    alignItems: "center",
  },
  circularProgress: {
    marginRight: theme.spacing(1),
  },
}));

// Returns the unix timestamp at which the device should refresh
function getRefreshTime(device: IDevice): number {
  const unixTimestamp =
    device.status.isOnline && device.latestCheckin
      ? Date.parse(device.latestCheckin.timestamp)
      : Date.parse(new Date().toUTCString());

  // Poll after 61 minutes (so the backend has a chance to get the data)
  return unixTimestamp + 1000 * 60 * 61;
}

interface DeviceCardProps {
  device: IDeviceEntity;
  imageOnly?: boolean;
  className?: string;
}

export const DeviceCard: React.FunctionComponent<DeviceCardProps> = ({
  device,
  imageOnly = false,
  className = "",
}) => {
  const dispatch = useDispatch();
  const insertIfAdmin = useInsertIfAdmin();

  const theme = useTheme();

  // Time to next refresh in seconds
  const [timeToRefresh, setTimeToRefresh] = useState<number>(0);
  // Unix timestamp of when to refresh
  const [refreshTime, setRefreshTime] = useState<number>(
    getRefreshTime(device)
  );

  const disabled = !device.status.isOnline;
  const classes = useStyles({ disabled, imageOnly });

  useEffect(() => {
    setRefreshTime(getRefreshTime(device));
  }, [device]);

  useEffect(() => {
    const interval = countdownTo(
      refreshTime,
      (timeLeft) => {
        setTimeToRefresh(timeLeft);
      },
      (timeLeft) => {
        setTimeToRefresh(timeLeft);
        dispatch(fetchDeviceById(device.id));
      }
    );

    return () => {
      window.clearInterval(interval);
    };
  }, [refreshTime, dispatch, device.id]);

  return (
    <Card className={`${classes.root} ${className}`}>
      <div className={`${classes.column} ${classes.left}`}>
        <CardMedia
          image={
            device.latestCheckin?.screenshotLocation
              ? device.latestCheckin.screenshotLocation
              : "don't give me warnings please"
          }
          className={classes.screenshot}
        />
      </div>
      <div className={`${classes.column} ${classes.right}`}>
        <Typography
          variant="subtitle2"
          component="h1"
          color="textSecondary"
          gutterBottom={true}
        >
          {device.serialNumber}
        </Typography>
        <PowerIndicator isPoweredOn={device.status.isOnline} />
        <TemperatureIndicator
          minTemp={device.type.minTemp}
          maxTemp={device.type.maxTemp}
          temp={!device.latestCheckin ? 0 : device.latestCheckin.temp}
          disabled={disabled}
        />
        <Typography>
          <small style={{ position: "relative", top: "0px" }}>
            {device.isLoading ? (
              <div className={classes.refreshingText}>
                <CircularProgress
                  className={classes.circularProgress}
                  size={theme.typography.body1.fontSize}
                  color="inherit"
                />{" "}
                Refreshing
              </div>
            ) : (
              `New data in ${secondsToFormattedHoursMinutes(timeToRefresh)}`
            )}
          </small>
          <br />
          {!device.latestCheckin
            ? "The device has not checked-in yet"
            : timestampToFormattedString(device.latestCheckin.timestamp)}{" "}
        </Typography>
        <InfoText
          className={classes.topMargin}
          icon={<WebIcon fontSize="inherit" />}
          label="Campaign"
          text={device.campaign ? device.campaign.name : "None"}
        />
        {insertIfAdmin(
          <InfoText
            icon={<MoodIcon fontSize="inherit" />}
            label="Customer"
            text={
              device.campaign?.customer ? device.campaign.customer.name : "None"
            }
          />,
          true
        )}
        <InfoText
          icon={<PublicIcon fontSize="inherit" />}
          label="Location"
          text={device.location}
        />
        <InfoText
          className={classes.topMargin}
          gutterBottom={true}
          icon={<UpArrowIcon fontSize="inherit" />}
          label="Uptime"
          text={secondsToFormattedHoursMinutes(device.status.uptime)}
        />
        <InfoText
          icon={<TouchAppIcon fontSize="inherit" />}
          label="Clicks this session"
          text={`${
            device.report
              ? `${device.report.clicksThisSession} clicks`
              : "The device has not checked-in yet"
          }`}
        />{" "}
      </div>
    </Card>
  );
};
