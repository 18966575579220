import { AxiosResponse } from "axios";
import instance from "../../axios";

export async function postUserCredentials(
  email: string,
  password: string
): Promise<AxiosResponse<{ mainToken: string; secondaryToken: string }>> {
  const response = await instance.post(`authentication/login`, {
    email,
    password,
  });
  return response;
}
