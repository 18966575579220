import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCampaigns,
  selectAllCampaigns,
  selectCampaignsFetching,
  createCampaign,
  updateCampaign,
  deleteCampaignById,
} from "../../../shared/campaigns";
import {
  useInsertIfAdmin,
  useIsUserAdmin,
  selectActiveUser,
} from "../../../shared/authentication";
import {
  selectCustomersFetching,
  useCustomersLookupObj,
} from "../../../shared/customers";
import {
  ICampaign,
  DisplayOrientation,
} from "../../../shared/backendApi/types/devices";
import {
  extractChromiumCommandInfo,
  createDefaultStartupCommands,
  getUrlFromString,
} from "../utils";
import { ICampaignRequestBody } from "../../../shared/backendApi/types/requestBodies";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

interface ICampaignRow {
  id: number;
  name: string;
  displayOrientation: DisplayOrientation;
  customer?: number;
  website?: string;
  hostRules?: string;
}

function createCampaignRows(campaigns: ICampaign[]): ICampaignRow[] {
  return campaigns.map((campaign) => {
    const [website, hostRules] = extractChromiumCommandInfo(
      campaign.startupCommands
    );
    return {
      id: campaign.id,
      name: campaign.name,
      customer: campaign.customer ? campaign.customer.id : undefined,
      website,
      hostRules: hostRules,
      displayOrientation: campaign.displayOrientation,
    };
  });
}

function createCampaignRequestBody(row: ICampaignRow): ICampaignRequestBody {
  return {
    name: row.name,
    customer: Number(row.customer),
    startupCommands: createDefaultStartupCommands(row.website, row.hostRules),
    displayOrientation: Number(row.displayOrientation),
  };
}

export const EditCampaignsPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const campaigns = useSelector(selectAllCampaigns);

  const insertIfAdmin = useInsertIfAdmin();
  const activeUser = useSelector(selectActiveUser);
  const isUserAdmin = useIsUserAdmin();

  const customersLookupObj = useCustomersLookupObj(true);

  const [isFetching, isCustomersFetching] = [
    useSelector(selectCampaignsFetching),
    useSelector(selectCustomersFetching),
  ];

  useEffect(() => {
    dispatch(fetchAllCampaigns());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>
      <AppDrawerMenuLayout>
        <div className={classes.tableWrapper}>
          <MaterialTable
            columns={[
              {
                title: "Name",
                field: "name",
                validate: (rowData) =>
                  rowData.name ? !!rowData.name.trim() : false,
              },
              {
                title: "Website",
                field: "website",
                validate: (rowData) =>
                  rowData.website ? !!getUrlFromString(rowData.website) : false,
              },
              { title: "Host rules", field: "hostRules" },
              ...insertIfAdmin({
                title: "Customer",
                field: "customer",
                lookup: customersLookupObj,
              }),
              {
                title: "Orientation",
                field: "displayOrientation",
                lookup: {
                  [DisplayOrientation.LANDSCAPE]: "Landscape",
                  [DisplayOrientation.PORTRAIT]: "Portrait",
                },
                initialEditValue: DisplayOrientation.LANDSCAPE,
              },
            ]}
            data={createCampaignRows(campaigns)}
            options={{
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 40, 80, 100],
            }}
            isLoading={isFetching || isCustomersFetching}
            editable={{
              onRowAdd: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    createCampaign({
                      campaignRequestBody: createCampaignRequestBody(
                        isUserAdmin
                          ? newRow
                          : { ...newRow, customer: activeUser?.customer?.id }
                      ),
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowUpdate: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    updateCampaign({
                      campaignId: newRow.id,
                      campaignRequestBody: createCampaignRequestBody(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowDelete: (row) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    deleteCampaignById({
                      campaignId: row.id,
                      onStopLoading: resolve,
                    })
                  );
                }),
            }}
            localization={{
              body: {
                editRow: {
                  deleteText:
                    "Are you sure? If any devices uses this campaign, they will display nothing once deleted.",
                },
              },
            }}
          />
        </div>
      </AppDrawerMenuLayout>
    </>
  );
};
