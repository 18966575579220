import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { LoginPage } from "./containers/loginPage";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={LoginPage} />
    </Switch>
  );
};
