import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

interface PowerIndicatorProps {
  isPoweredOn: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  circle: ({ isPoweredOn }: PowerIndicatorProps) => ({
    borderRadius: 5000,
    height: 10,
    width: 10,
    backgroundColor: isPoweredOn
      ? theme.palette.success.light
      : theme.palette.text.disabled,
    marginRight: theme.spacing(1),
  }),
  text: ({ isPoweredOn }: PowerIndicatorProps) => ({
    color: isPoweredOn
      ? theme.palette.success.light
      : theme.palette.text.disabled,
  }),
}));

export const PowerIndicator: React.FunctionComponent<PowerIndicatorProps> = ({
  isPoweredOn,
}) => {
  const classes = useStyles({ isPoweredOn });

  return (
    <div className={classes.root}>
      <div className={classes.circle} />
      <Typography className={classes.text} variant="h6" component="p">
        {isPoweredOn ? "Powered on" : "Powered off"}
      </Typography>
    </div>
  );
};
