import React from "react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { PaletteColor } from "@material-ui/core/styles/createPalette";

interface TemperatureIndicatorProps {
  temp: number;
  minTemp: number;
  maxTemp: number;
  disabled: boolean;
}

interface SliderStyleProps {
  color: PaletteColor;
  disabled: boolean;
}

const sliderHeight = 10;
const useSliderStyles = makeStyles((theme) => ({
  root: {
    height: sliderHeight,
    padding: 0,
    marginRight: theme.spacing(1),
    width: "80%",
  },
  rail: {
    borderRadius: 10,
    height: sliderHeight,
    backgroundColor: theme.palette.grey.A700,
  },
  track: ({ color, disabled }: SliderStyleProps) => ({
    borderRadius: 10,
    height: sliderHeight,
    backgroundColor: !disabled ? color.main : theme.palette.text.disabled,
  }),
  thumb: {
    display: "none",
  },
  mark: {
    height: "50%",
    width: 1,
    backgroundColor: theme.palette.grey.A200,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

function findTempColor(
  temp: number,
  limit1: number,
  limit2: number,
  theme: Theme,
  disabled: boolean
): PaletteColor {
  if (temp <= limit1) {
    return theme.palette.info;
  } else if (temp > limit1 && temp <= limit2) {
    return theme.palette.warning;
  } else {
    return theme.palette.error;
  }
}

export const TemperatureIndicator: React.FunctionComponent<TemperatureIndicatorProps> = ({
  temp,
  maxTemp,
  minTemp,
  disabled,
}) => {
  const theme = useTheme();

  // Figure out color
  const [oneThird, twoThird] = [
    (maxTemp - minTemp) / 3 + minTemp,
    ((maxTemp - minTemp) * 2) / 3 + minTemp,
  ];
  const color = findTempColor(temp, oneThird, twoThird, theme, disabled);

  // create classes
  const classes = useStyles();
  const sliderClasses = useSliderStyles({ color, disabled });

  // create marks at thirds
  const marks = [
    {
      value: oneThird,
    },
    {
      value: twoThird,
    },
  ];

  return (
    <div className={classes.root}>
      <Slider
        classes={sliderClasses}
        disabled={true}
        marks={marks}
        defaultValue={temp}
        min={minTemp - 1}
        max={maxTemp}
      />
      <Typography variant="caption" noWrap={true}>
        {temp.toFixed(1)} C
      </Typography>
    </div>
  );
};
