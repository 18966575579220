import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCustomers, fetchAllCustomers } from "./customersSlice";

export function useCustomersLookupObj(fetch = false) {
  const customers = useSelector(selectAllCustomers);
  const dispatch = useDispatch();

  // Fetch customers if told to
  useEffect(
    function () {
      if (fetch) {
        dispatch(fetchAllCustomers());
      }
    },
    [fetch, dispatch]
  );

  const lookupObj = { undefined: "No customer" };
  customers.forEach((customer) => {
    Object.assign(lookupObj, { [customer.id]: customer.name });
  });
  return lookupObj;
}
