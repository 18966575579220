import { IUser } from "../../shared/backendApi/types/user";
export enum FetchingError {
  wrongCredentials,
  unknownError,
}

export enum FetchingStatus {
  loggingIn,
  idle,
}

export interface AuthenticationState {
  activeUser?: IUser;
  fetchingStatus: FetchingStatus;
  fetchingErrors: FetchingError[];
}
