import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { DeviceMonitor } from "./containers/deviceMonitor";
import { EditDevicesPage } from "./containers/editDevicesPage";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={EditDevicesPage} />
      <Route exact path={`${path}/monitor`} component={DeviceMonitor} />
    </Switch>
  );
};
