import React from "react";
import { Switch, Route } from "react-router-dom";
import DevicesRouter from "./features/devices/router";
import CampaignsRouter from "./features/campaigns/router";
import AuthenticationRouter from "./features/authentication/router";
import CustomersRouter from "./features/customers/router";
import UsersRouter from "./features/users/router";
import DeviceTypesRouter from "./features/deviceTypes/router";
import { useRedirectToIfUnauthenticated } from "./shared/authentication";

export const AppRouter: React.FunctionComponent = () => {
  useRedirectToIfUnauthenticated("/authentication");
  return (
    <Switch>
      <Route path="/users" component={UsersRouter} />
      <Route path="/device-types" component={DeviceTypesRouter} />
      <Route path="/customers" component={CustomersRouter} />
      <Route path="/campaigns" component={CampaignsRouter} />
      <Route path="/devices" component={DevicesRouter} />
      <Route path="/authentication" component={AuthenticationRouter} />
      <Route path="/" component={AuthenticationRouter} />
    </Switch>
  );
};
