import { ICheckinLog } from "./checkin";
import { ICustomer } from "./customer";
import { ISession } from "./session";

export enum ExecutionMode {
  EXEC,
  SPAWN,
}

export type macAddress = string;

export interface ICommand {
  body: string;
  executionMode: ExecutionMode;
  args?: string[];
}

export function isICommand(obj: any): obj is ICommand {
  const hasBody = typeof obj.body === "string";
  const hasExecutionMode =
    obj.executionMode === ExecutionMode.EXEC ||
    obj.executionMode === ExecutionMode.SPAWN;
  const isArgsCorrect = obj.args
    ? Array.isArray(obj.args)
      ? obj.args
          .map((arg: any) => typeof arg === "string")
          .filter((isArg: boolean) => !isArg).length === 0
      : false
    : true;
  return hasBody && hasExecutionMode && isArgsCorrect;
}

export enum DisplayOrientation {
  PORTRAIT,
  LANDSCAPE,
}

export function isDisplayOrientation(obj: any): obj is DisplayOrientation {
  return (
    obj === DisplayOrientation.PORTRAIT || obj === DisplayOrientation.LANDSCAPE
  );
}

export interface ICampaign {
  id: number;
  startupCommands: ICommand[];
  name: string;
  customer?: ICustomer;
  displayOrientation: DisplayOrientation;
}

export interface IDeviceStatus {
  isOnline: boolean;
  uptime: number;
}

export interface IDeviceType {
  id: number;
  name: string;
  minTemp: number;
  maxTemp: number;
  isDeleteable: boolean;
}

export interface IDeviceReport {
  clicksThisSession: number;
}

export interface IDevice {
  id: number;
  campaign?: ICampaign;
  macAddress: macAddress;
  serialNumber?: string;
  location: string;
  latestCheckin: ICheckinLog | undefined;
  latestSession: ISession | undefined;
  report?: IDeviceReport;
  status: IDeviceStatus;
  type: IDeviceType;
  isDeleteable: boolean;
  displayOrientation?: DisplayOrientation;
  customer?: ICustomer;
}
