import { AxiosResponse } from "axios";
import instance from "../../axios";
import { IDeviceType } from "../backendApi/types/devices";
import { IDeviceTypeRequestBody } from "../backendApi/types/requestBodies";

export async function getDeviceTypes(): Promise<AxiosResponse<IDeviceType[]>> {
  const response = await instance.get("devices/types");
  return response;
}

export async function postDeviceType(requestBody: IDeviceTypeRequestBody) {
  return await instance.post("devices/types", requestBody);
}

export async function putDeviceType(
  id: number,
  requestBody: IDeviceTypeRequestBody
) {
  return await instance.put(`devices/types/${id}`, requestBody);
}

export async function deleteDeviceType(id: number) {
  return await instance.delete(`devices/types/${id}`);
}
