import {
  createEntityAdapter,
  EntityState,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { ICustomer } from "../backendApi/types/customer";
import { ICustomerRequestBody } from "../backendApi/types/requestBodies";
import { RootState } from "../../store/types";
import {
  getCustomers,
  putCustomer,
  postCustomer,
  deleteCustomer,
} from "./backendApi";

const sliceName = "customers";

// --- Thunks
export const fetchAllCustomers = createAsyncThunk(
  `${sliceName}/fetchAll`,
  async (thunkApi) => {
    const response = await getCustomers();
    return response.data;
  }
);

interface CreateCustomerArgs {
  customerRequestBody: ICustomerRequestBody;
  onStopLoading: () => void;
}
export const createCustomer = createAsyncThunk(
  `${sliceName}/createCustomer`,
  async (
    { customerRequestBody, onStopLoading }: CreateCustomerArgs,
    thunkApi
  ) => {
    const response = await postCustomer(customerRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface UpdateCustomerArgs {
  customerId: number;
  customerRequestBody: ICustomerRequestBody;
  onStopLoading: () => void;
}
export const updateCustomer = createAsyncThunk(
  `${sliceName}/updateCustomer`,
  async ({
    customerId,
    customerRequestBody,
    onStopLoading,
  }: UpdateCustomerArgs) => {
    const response = await putCustomer(customerId, customerRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface DeleteCustomerArgs {
  customerId: number;
  onStopLoading: () => void;
}
export const deleteCustomerById = createAsyncThunk(
  `${sliceName}/deleteCustomer`,
  async ({ customerId, onStopLoading }: DeleteCustomerArgs, thunkAPI) => {
    await deleteCustomer(customerId);
    onStopLoading();
  }
);

// --- Setting up slice
export interface CustomersState extends EntityState<ICustomer> {
  fetching: boolean;
}

const customersAdapter = createEntityAdapter<ICustomer>();
const initialState: CustomersState = customersAdapter.getInitialState({
  fetching: false,
});

const customersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCustomers.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(fetchAllCustomers.fulfilled, (state, action) => {
      customersAdapter.upsertMany(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(fetchAllCustomers.rejected, (state, action) => {
      state.fetching = false;
    });

    // Create customer
    builder.addCase(createCustomer.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      customersAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.fetching = false;
    });

    // Update customer
    builder.addCase(updateCustomer.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      customersAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.fetching = false;
    });

    // Delete customer
    builder.addCase(deleteCustomerById.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(deleteCustomerById.fulfilled, (state, action) => {
      const customerId = action.meta.arg.customerId;
      customersAdapter.removeOne(state, customerId);
      state.fetching = false;
    });
    builder.addCase(deleteCustomerById.rejected, (state, action) => {
      state.fetching = false;
    });
  },
});

// --- Selectors
function selectCustomersState(state: RootState): CustomersState {
  return state.customers;
}

export function selectFetching(state: RootState): boolean {
  return selectCustomersState(state).fetching;
}

export const { selectAll: selectAllCustomers } = customersAdapter.getSelectors(
  selectCustomersState
);

export default customersSlice.reducer;
