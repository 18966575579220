import { AxiosRequestConfig, AxiosError } from "axios";
import { getMainToken, dumbLogOut } from "./utils";
import customBrowserHistory from "../../history";

/* Interceptor responsible for loading token stored in session storage, 
and adding it to the request*/
export function tokenInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  const mainToken = getMainToken();
  return { ...config, headers: { ...config.headers, "main-token": mainToken } };
}

/* Interceptor responsible for redirecting to the authentication page,
  if the requests was denied. */
export function unauthenticatedRequestInterceptor(
  error: AxiosError
): Promise<AxiosError> {
  if (
    error.response?.status === 401 &&
    customBrowserHistory.location.pathname !== "/authentication"
  ) {
    dumbLogOut("/authentication");
  }
  return Promise.reject(error);
}
