import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  PayloadAction,
  EntityState,
} from "@reduxjs/toolkit";
import { IDevice } from "../../shared/backendApi/types/devices";
import {
  getDevices,
  getDevice,
  deleteDevice,
  postDevice,
  putDevice,
} from "./backendApi";
import { RootState } from "../../store/types";
import { IDeviceRequestBody } from "../../shared/backendApi/types/requestBodies";
export interface IDeviceEntity extends IDevice {
  isLoading: boolean;
}
export interface DevicesState extends EntityState<IDeviceEntity> {
  fetching: boolean;
}

const sliceName = "devices";

// --- Thunks
export const fetchAllDevices = createAsyncThunk(
  `${sliceName}/fetchAll`,
  async (thunkAPI) => {
    const response = await getDevices();
    return response.data.map((device) => ({
      ...device,
      isLoading: false,
    })) as IDeviceEntity[];
  }
);

export const fetchDeviceById = createAsyncThunk(
  `${sliceName}/fetchDeviceById`,
  //TODO: Is there a reason i called this userId and not deviceId?
  async (userId: number, thunkAPI) => {
    const response = await getDevice(userId);
    return { ...response.data, isLoading: false } as IDeviceEntity;
  }
);

interface deleteDeviceByIdArgs {
  deviceId: number;
  onStopLoading: () => void;
}
export const deleteDeviceById = createAsyncThunk(
  `${sliceName}/deleteDeviceById`,
  async ({ deviceId, onStopLoading }: deleteDeviceByIdArgs, thunkApi) => {
    await deleteDevice(deviceId);
    onStopLoading();
  }
);

interface createDeviceArgs {
  deviceRequestBody: IDeviceRequestBody;
  onStopLoading: () => void;
}
export const createDevice = createAsyncThunk(
  `${sliceName}/createDevice`,
  async ({ deviceRequestBody, onStopLoading }: createDeviceArgs, thunkAPI) => {
    const response = await postDevice(deviceRequestBody);
    onStopLoading();
    return { ...response.data, isLoading: false } as IDeviceEntity;
  }
);

interface updateDeviceArgs {
  deviceId: number;
  deviceRequestBody: IDeviceRequestBody;
  onStopLoading: () => void;
}
export const updateDevice = createAsyncThunk(
  `${sliceName}/updateDevice`,
  async ({ deviceId, deviceRequestBody, onStopLoading }: updateDeviceArgs) => {
    const response = await putDevice(deviceId, deviceRequestBody);
    onStopLoading();
    return { ...response.data, isLoading: false } as IDeviceEntity;
  }
);

// --- Setting up slice
const devicesAdapter = createEntityAdapter<IDeviceEntity>();

const initialState: DevicesState = devicesAdapter.getInitialState({
  fetching: false,
  fetchingList: [],
});

// --  Util functions used in reducers
function startFetching(state: DevicesState) {
  state.fetching = true;
}

function stopFetching(state: DevicesState) {
  state.fetching = false;
}

const devicesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDevices.pending, startFetching);

    builder.addCase(fetchDeviceById.pending, (state, action) => {
      const deviceId = action.meta.arg;
      devicesAdapter.updateOne(state, {
        id: deviceId,
        changes: { isLoading: true },
      });
      startFetching(state);
    });

    builder.addCase(
      fetchAllDevices.fulfilled,
      (state, action: PayloadAction<IDeviceEntity[]>) => {
        devicesAdapter.upsertMany(state, action.payload);
        stopFetching(state);
      }
    );

    builder.addCase(
      fetchDeviceById.fulfilled,
      (state, action: PayloadAction<IDeviceEntity>) => {
        devicesAdapter.upsertOne(state, action.payload);
        stopFetching(state);
      }
    );

    builder.addCase(deleteDeviceById.pending, (state, action) => {
      const deviceId = action.meta.arg.deviceId;
      devicesAdapter.updateOne(state, {
        id: deviceId,
        changes: { isLoading: true },
      });
    });

    builder.addCase(deleteDeviceById.fulfilled, (state, action) => {
      const deviceId = action.meta.arg.deviceId;
      devicesAdapter.removeOne(state, deviceId);
    });

    builder.addCase(deleteDeviceById.rejected, (state, action) => {
      const deviceId = action.meta.arg.deviceId;
      devicesAdapter.updateOne(state, {
        id: deviceId,
        changes: { isLoading: false },
      });
    });

    builder.addCase(createDevice.pending, (state, action) => {
      startFetching(state);
    });

    builder.addCase(createDevice.fulfilled, (state, action) => {
      devicesAdapter.upsertOne(state, action.payload);
      stopFetching(state);
    });

    builder.addCase(createDevice.rejected, (state, action) => {
      stopFetching(state);
    });

    builder.addCase(updateDevice.pending, (state, action) => {
      const deviceId = action.meta.arg.deviceId;
      devicesAdapter.updateOne(state, {
        id: deviceId,
        changes: { isLoading: true },
      });
      startFetching(state);
    });

    builder.addCase(updateDevice.fulfilled, (state, action) => {
      devicesAdapter.upsertOne(state, action.payload);
      stopFetching(state);
    });
  },
});

// --- Selectors
function selectDevicesState(state: RootState): DevicesState {
  return state.devices;
}

export function selectFetching(state: RootState): boolean {
  return selectDevicesState(state).fetching;
}

export const { selectAll: selectAllDevices } = devicesAdapter.getSelectors(
  (state: RootState) => state.devices
);

export default devicesSlice.reducer;
