import {
  createEntityAdapter,
  EntityState,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { ICampaign } from "../backendApi/types/devices";
import { RootState } from "../../store/types";
import {
  getCampaigns,
  postCampaign,
  putCampaign,
  deleteCampaign,
} from "./backendApi";
import { ICampaignRequestBody } from "../backendApi/types/requestBodies";

const sliceName = "campaigns";

// --- Thunks
export const fetchAllCampaigns = createAsyncThunk(
  `${sliceName}/fetchAll`,
  async (thunkAPI) => {
    const response = await getCampaigns();
    return response.data;
  }
);

interface createCampaignArgs {
  campaignRequestBody: ICampaignRequestBody;
  onStopLoading: () => void;
}
export const createCampaign = createAsyncThunk(
  `${sliceName}/createCampaign`,
  async (
    { campaignRequestBody, onStopLoading }: createCampaignArgs,
    thunkAPI
  ) => {
    const response = await postCampaign(campaignRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface updateCampaignArgs {
  campaignId: number;
  campaignRequestBody: ICampaignRequestBody;
  onStopLoading: () => void;
}
export const updateCampaign = createAsyncThunk(
  `${sliceName}/updateCampaign`,
  async (
    { campaignId, campaignRequestBody, onStopLoading }: updateCampaignArgs,
    thunkApi
  ) => {
    const response = await putCampaign(campaignId, campaignRequestBody);
    onStopLoading();
    return response.data;
  }
);

interface deleteCampaignArgs {
  campaignId: number;
  onStopLoading: () => void;
}
export const deleteCampaignById = createAsyncThunk(
  `${sliceName}/deleteCampaign`,
  async ({ campaignId, onStopLoading }: deleteCampaignArgs, thunkAPI) => {
    await deleteCampaign(campaignId);
    onStopLoading();
  }
);

// --- Setting up slice
export interface CampaignsState extends EntityState<ICampaign> {
  fetching: boolean;
}
const campaignsAdapter = createEntityAdapter<ICampaign>();
const initialState: CampaignsState = campaignsAdapter.getInitialState({
  fetching: false,
});

const campaignsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch all campaigns
    builder.addCase(fetchAllCampaigns.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(fetchAllCampaigns.fulfilled, (state, action) => {
      campaignsAdapter.upsertMany(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(fetchAllCampaigns.rejected, (state, action) => {
      state.fetching = false;
    });

    // Create Campaign
    builder.addCase(createCampaign.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      campaignsAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.fetching = true;
    });

    // Update Campaign
    builder.addCase(updateCampaign.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      campaignsAdapter.upsertOne(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.fetching = false;
    });

    // Delete Campaign
    builder.addCase(deleteCampaignById.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(deleteCampaignById.fulfilled, (state, action) => {
      const campaignId = action.meta.arg.campaignId;
      campaignsAdapter.removeOne(state, campaignId);
      state.fetching = false;
    });
    builder.addCase(deleteCampaignById.rejected, (state, action) => {
      state.fetching = false;
    });
  },
});

// --- Selectors
function selectCampaignsState(state: RootState): CampaignsState {
  return state.campaigns;
}

export function selectFetching(state: RootState): boolean {
  return selectCampaignsState(state).fetching;
}

export const { selectAll: selectAllCampaigns } = campaignsAdapter.getSelectors(
  (state: RootState) => state.campaigns
);

export default campaignsSlice.reducer;
