import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCustomers,
  fetchAllCustomers,
  selectCustomersFetching,
  createCustomer,
  deleteCustomerById,
  updateCustomer,
} from "../../../shared/customers";
import { ICustomer } from "../../../shared/backendApi/types/customer";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

interface ICustomerRow {
  id: number;
  name: string;
  isDeleteable: boolean;
}
function createCustomerRows(customers: ICustomer[]): ICustomerRow[] {
  return customers.map((customer) => ({
    id: customer.id,
    name: customer.name,
    isDeleteable: customer.isDeleteable,
  }));
}

export const EditCustomersPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customers = useSelector(selectAllCustomers);
  const isFetching = useSelector(selectCustomersFetching);

  useEffect(() => {
    dispatch(fetchAllCustomers());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <AppDrawerMenuLayout>
        <div className={classes.tableWrapper}>
          <MaterialTable
            isLoading={isFetching}
            columns={[
              {
                title: "Name",
                field: "name",
                validate: (rowData) =>
                  rowData.name ? !!rowData.name.trim() : false,
              },
            ]}
            data={createCustomerRows(customers)}
            options={{
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 40, 80, 100],
            }}
            editable={{
              isDeletable: (row) => row.isDeleteable,
              onRowAdd: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    createCustomer({
                      customerRequestBody: { name: newRow.name },
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowUpdate: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    updateCustomer({
                      customerId: newRow.id,
                      customerRequestBody: { name: newRow.name },
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowDelete: (row) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    deleteCustomerById({
                      customerId: row.id,
                      onStopLoading: resolve,
                    })
                  );
                }),
            }}
          />
        </div>
      </AppDrawerMenuLayout>
    </>
  );
};
