import { AxiosResponse } from "axios";
import instance from "../../axios";
import { ICampaign } from "../backendApi/types/devices";
import { ICampaignRequestBody } from "../backendApi/types/requestBodies";

export async function getCampaigns(): Promise<AxiosResponse<ICampaign[]>> {
  return await instance.get("campaigns");
}

export async function postCampaign(
  campaignRequestBody: ICampaignRequestBody
): Promise<AxiosResponse<ICampaign>> {
  return await instance.post("campaigns", campaignRequestBody);
}

export async function deleteCampaign(id: number): Promise<AxiosResponse<void>> {
  return await instance.delete(`campaigns/${id}`);
}

export async function putCampaign(
  id: number,
  campaignRequestBody: ICampaignRequestBody
): Promise<AxiosResponse<ICampaign>> {
  return await instance.put(`campaigns/${id}`, campaignRequestBody);
}
