import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { postUserCredentials } from "./backendApi";
import { IUser } from "../../shared/backendApi/types/user";
import { parseJWT, setMainToken } from "./utils";
import { FetchingError, FetchingStatus, AuthenticationState } from "./types";
import { IMainToken } from "../../shared/backendApi/types/tokens";
import { RootState } from "../../store/types";

const sliceName = "authentication";

// --- Thunks
interface LoginUserArgs {
  email: string;
  password: string;
}

interface LoginUserRejectedPayload {
  fetchingError: FetchingError;
}
export const loginUser = createAsyncThunk(
  `${sliceName}/loginUser`,
  async ({ email, password }: LoginUserArgs, thunkAPI) => {
    // Request a jwt
    try {
      const response = await postUserCredentials(email, password);
      const mainToken = response.data.mainToken;
      // Save the unparsed jwt
      setMainToken(mainToken);
      // decode the body and return the user
      const user = (parseJWT(mainToken) as IMainToken).user;
      thunkAPI.dispatch(setActiveUser(user));
    } catch (e) {
      // Manually dispatch rejected, otherwise we won't get status code which is necessary
      const payload: LoginUserRejectedPayload = {
        fetchingError:
          e.response?.status === 401
            ? FetchingError.wrongCredentials
            : FetchingError.unknownError,
      };
      return thunkAPI.rejectWithValue(payload);
    }
  }
);

// --- Setting up slice
const initialState: AuthenticationState = {
  fetchingStatus: FetchingStatus.idle,
  fetchingErrors: [],
};

const authenticationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setActiveUser: (
      state: AuthenticationState,
      action: PayloadAction<IUser | undefined>
    ) => {
      state.activeUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.fetchingStatus = FetchingStatus.idle;
    });

    builder.addCase(loginUser.pending, (state, action) => {
      state.fetchingStatus = FetchingStatus.loggingIn;
      state.fetchingErrors = [];
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      const { fetchingError } = action.payload as LoginUserRejectedPayload;
      state.fetchingErrors.push(fetchingError);
      state.fetchingStatus = FetchingStatus.idle;
    });
  },
});

export default authenticationSlice.reducer;
export const { setActiveUser } = authenticationSlice.actions;

// --- Selectors
function selectAuthenticationState(state: RootState): AuthenticationState {
  return state.authentication;
}

export function selectFetchingErrors(state: RootState): FetchingError[] {
  return selectAuthenticationState(state).fetchingErrors;
}

export function selectFetchingStatus(state: RootState): FetchingStatus {
  return selectAuthenticationState(state).fetchingStatus;
}

export function selectActiveUser(state: RootState): IUser | undefined {
  return selectAuthenticationState(state).activeUser;
}
