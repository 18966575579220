import { ICommand } from "../../shared/backendApi/types/devices";

export function getUrlFromString(str: string): string | undefined {
  // Attempt to find the url from the args
  const matches = str.match(
    /(https?|ftp|file):\/\/[A-Za-z0-9.-]+(\/[A-Za-z0-9?&=;+!'()*\-._~%]*)*/gm
  );
  return matches === null ? undefined : matches[0];
}

export function getHostRulesFromString(str: string): string | undefined {
  const matches = /--host-rules='{1}(.*)'{1}/gm.exec(str);
  if (matches === null) {
    return undefined;
  } else {
    return matches[1] ? matches[1] : undefined;
  }
}
// Util function to find the website a campaign is using from its commands
export function extractChromiumCommandInfo(
  commands: ICommand[]
): [string | undefined, string | undefined] {
  const chromiumCommands = commands.filter(
    (command) => command.body.toLowerCase() === "chromium-browser"
  );

  if (chromiumCommands.length === 0) {
    return [undefined, undefined];
  } else if (chromiumCommands.length === 1) {
    // Find the chromium command and get its arguments
    const chromiumCommand = chromiumCommands[0];
    const args = chromiumCommand.args ? chromiumCommand.args[0] : undefined;
    // If no arguments found just return undefined
    if (!args) return [undefined, undefined];

    // Attempt to find the url from the args
    return [getUrlFromString(args), getHostRulesFromString(args)];
  } else {
    return ["Error: found several chromium-commands", undefined];
  }
}

export function createDefaultStartupCommands(
  website?: string,
  hostRules?: string
): ICommand[] {
  return [
    {
      executionMode: 0,
      body:
        "find /home/pi/stibo-interactive/ -mindepth 1 -maxdepth 1 -type d -print -exec git -C {} pull \\;",
    },
    {
      executionMode: 1,
      body: "chromium-browser",
      args: [
        `--kiosk '${website}' --disable-pinch --overscroll-history-navigation=0 ${
          hostRules ? `--host-rules='${hostRules}'` : ""
        } --load-extension=/home/pi/stibo-interactive/stibo-screen-chrome-extension/dist/`,
      ],
    },
    {
      executionMode: 1,
      body: "node",
      args: [
        "/home/pi/stibo-interactive/stibo-screen-client/dist/src/checkinRoutine.js",
      ],
    },
  ];
}
