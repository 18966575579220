import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

interface InfoTextProps {
  gutterBottom?: boolean;
  label: string;
  text: string | React.ReactElement;
  icon: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  labelLine: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  text: {
    marginLeft: theme.spacing(0.5),
  },
}));

export const InfoText: React.FunctionComponent<InfoTextProps> = ({
  label,
  text,
  icon,
  className,
  gutterBottom,
}) => {
  const classes = useStyles();
  return (
    <div className={!!className ? className : ""}>
      <div className={classes.labelLine}>
        {icon}
        <Typography
          className={classes.text}
          variant="body2"
          color="textSecondary"
        >
          {label}
        </Typography>
      </div>
      <Typography gutterBottom={gutterBottom} variant="body1">
        {text}
      </Typography>
    </div>
  );
};
