import { ICustomer } from "./customer";

export enum Role {
  ADMINISTRATOR,
  USER,
}

export function isRole(prop: any): prop is Role {
  return prop === Role.ADMINISTRATOR || prop === Role.USER;
}

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  customer?: ICustomer;
  role: Role;
}
