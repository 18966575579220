import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setActiveUser, selectActiveUser } from "./authenticationSlice";
import { getMainToken, parseJWT, isParsedTokenExpired } from "./utils";
import { IMainToken } from "../../shared/backendApi/types/tokens";
import { Role } from "../../shared/backendApi/types/user";

// Checks if the user is authenticated - created as a hook instead of a
// memoized selector, in order to be able to use other hooks.
export function useIsAuthenticated(shouldSetActiveUser = true): boolean {
  const dispatch = useDispatch();
  const mainToken = getMainToken();
  if (mainToken) {
    const parsedMainToken: IMainToken = parseJWT(mainToken);
    const isTokenValid = !isParsedTokenExpired(parsedMainToken);
    if (isTokenValid) {
      if (shouldSetActiveUser) {
        dispatch(setActiveUser(parsedMainToken.user));
      }
      return true;
    }
  }
  return false;
}

// Returns true if the user is admin
export function useIsUserAdmin(): boolean {
  const activeUser = useSelector(selectActiveUser);
  return activeUser?.role === Role.ADMINISTRATOR;
}

// returns  a list with the objects if the user is admin,
// returns a list such that it can be used with the spread operator
export function useInsertIfAdmin(): (
  obj: any | any[],
  returnUnwrapped?: boolean
) => [] | any[] {
  const isUserAdmin = useIsUserAdmin();
  return (obj: any | any[], returnUnwrapped = false) => {
    if (isUserAdmin) {
      return returnUnwrapped ? obj : Array.isArray(obj) ? obj : [obj];
    } else {
      return [];
    }
  };
}

function useRedirectToOnAuthenticationState(
  neededAuthState: boolean,
  destination: string
): void {
  const isAuthenticated: boolean = useIsAuthenticated();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (
      isAuthenticated === neededAuthState &&
      location.pathname !== destination
    ) {
      history.push(destination);
    }
  }, [location, destination, isAuthenticated, neededAuthState, history]);
}

// Redirect to given page, if the user is not already authenticated
export function useRedirectToIfUnauthenticated(destination: string): void {
  useRedirectToOnAuthenticationState(false, destination);
}

// Redirect a user to the given page, if they are already authenticated
export function useRedirectToIfAuthenticated(destination: string): void {
  useRedirectToOnAuthenticationState(true, destination);
}
