/* Counts down to a point in time
to: unix timestamp specifying when onTrigger should run 
onTick: callback called every tickInterval   
onTrigger: callback called when to is reached 
tickInterval: The interval at which onTick is executed in secodns
returns an interval
*/
export function countdownTo(
  to: number,
  onTick: (timeLeft: number) => any,
  onTrigger: (timeLeft: number) => any,
  tickInterval = 1
): number {
  function calcTimeLeft() {
    const now = Date.parse(new Date().toUTCString());
    return (to - now) / 1000;
  }

  onTick(calcTimeLeft());
  const interval = window.setInterval(function () {
    const timeLeft = calcTimeLeft();
    if (timeLeft > 0) {
      onTick(timeLeft);
    } else {
      onTrigger(0);
      window.clearInterval(interval);
    }
  }, tickInterval * 1000);
  return interval;
  //return () => window.clearInterval(interval);
}
