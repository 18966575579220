import { configureStore, combineReducers } from "@reduxjs/toolkit";
import devicesReducer from "../features/devices/devicesSlice";
import authenticationReducer from "../shared/authentication/authenticationSlice";
import deviceTypesReducer from "../shared/deviceTypes/deviceTypesSlice";
import campaignsReducer from "../shared/campaigns/campaignsSlice";
import customersReducer from "../shared/customers/customersSlice";
import usersReducer from "../shared/users/usersSlice";

export const rootReducer = combineReducers({
  devices: devicesReducer,
  authentication: authenticationReducer,
  deviceTypes: deviceTypesReducer,
  campaigns: campaignsReducer,
  customers: customersReducer,
  users: usersReducer,
});
//export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
});

//export type AppDispatch = typeof store.dispatch;

export default store;
