import { IMainToken } from "../../shared/backendApi/types/tokens";

/* I'm no JWT genious, so this is stolen from: 
https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library */
export function parseJWT(token: string): any {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export function getMainToken() {
  return window.localStorage.getItem("mainToken");
}

export function setMainToken(value: any): void {
  window.localStorage.setItem("mainToken", value);
}

export function isParsedTokenExpired(parsedToken: IMainToken): boolean {
  return Date.now() / 1000 >= parsedToken.exp;
}

/* This is a "good enough" solution that leads to activeUser being cleared, because we remove the main token. By no means a perfect solution, and i would like to turn this into a thunk, that sets activeUser to undefined but i have had some trouble doing this, due to circular dependencies. Enough time has been spent on it for now and might be revisited in the future if necessary*/
export const dumbLogOut = (goTo?: string) => {
  window.localStorage.removeItem("mainToken");
  if (goTo) {
    window.location.replace("/authentication");
  }
};
