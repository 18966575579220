import { AxiosResponse } from "axios";
import instance from "../../axios";
import { ICustomer } from "../backendApi/types/customer";
import { ICustomerRequestBody } from "../backendApi/types/requestBodies";

export async function getCustomers(): Promise<AxiosResponse<ICustomer[]>> {
  const response = await instance.get("customers");
  return response;
}

export async function deleteCustomer(id: number): Promise<AxiosResponse<void>> {
  return await instance.delete(`customers/${id}`);
}

export async function putCustomer(
  id: number,
  requestBody: ICustomerRequestBody
) {
  return await instance.put(`customers/${id}`, requestBody);
}

export async function postCustomer(requestBody: ICustomerRequestBody) {
  return await instance.post(`customers`, requestBody);
}
