import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AppDrawerMenuLayout } from "../../../shared/ui";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllUsers,
  fetchAllUsers,
  createUser,
  deleteUserById,
  selectUsersFetching,
  updateUser,
} from "../../../shared/users";
import {
  useCustomersLookupObj,
  selectCustomersFetching,
} from "../../../shared/customers";
import { selectActiveUser } from "../../../shared/authentication";
import { IUser, Role } from "../../../shared/backendApi/types/user";
import { IUserRequestBody } from "../../../shared/backendApi/types/requestBodies";

const emptyPassStr = "********";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

interface IUserRow {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  password?: string;
  customer?: number;
}

function createUserRequestBody(row: IUserRow): IUserRequestBody {
  let requestBody: IUserRequestBody = {
    firstName: row.firstName,
    lastName: row.lastName,
    role: Number(row.role),
    email: row.email,
    customer: Number(row.customer),
  };
  if (
    row.password &&
    row.password.trim() !== "" &&
    row.password !== emptyPassStr
  ) {
    requestBody["password"] = row.password;
  }
  return requestBody;
}

function createUserRows(users: IUser[]): IUserRow[] {
  return users.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    password: emptyPassStr,
    customer: user.customer ? user.customer.id : undefined,
    email: user.email,
    role: user.role,
  }));
}

export const EditUsersPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const users = useSelector(selectAllUsers);
  const activeUser = useSelector(selectActiveUser);

  const customersLookupObj = useCustomersLookupObj(true);

  const [isUsersFetching, isCustomersFetching] = [
    useSelector(selectUsersFetching),
    useSelector(selectCustomersFetching),
  ];

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <AppDrawerMenuLayout>
        <div className={classes.tableWrapper}>
          <MaterialTable
            columns={[
              {
                title: "First name",
                field: "firstName",
                validate: (rowData) =>
                  rowData.firstName ? !!rowData.firstName.trim() : false,
              },
              {
                title: "Last name",
                field: "lastName",
                validate: (rowData) =>
                  rowData.lastName ? !!rowData.lastName.trim() : false,
              },
              {
                title: "Email",
                field: "email",
                validate: (rowData) => {
                  // Check if it is empty
                  if (rowData.email && !!rowData.email.trim()) {
                    // Check if the email is unique
                    return true;
                  }
                  return false;
                },
              },
              {
                title: "Customer",
                field: "customer",
                lookup: customersLookupObj,
              },
              {
                title: "Role",
                field: "role",
                initialEditValue: Role.USER,
                lookup: {
                  [Role.ADMINISTRATOR]: "Administrator",
                  [Role.USER]: "User",
                },
              },
              {
                title: "Password",
                field: "password",
                validate: (rowData) =>
                  rowData.password ? !!rowData.password.trim() : false,
              },
            ]}
            data={createUserRows(users)}
            options={{
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 40, 80, 100],
            }}
            isLoading={isUsersFetching || isCustomersFetching}
            editable={{
              isDeletable: (row) => row.id !== activeUser?.id,
              onRowAdd: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    createUser({
                      userRequestBody: createUserRequestBody(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
              onRowDelete: (row) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    deleteUserById({ userId: row.id, onStopLoading: resolve })
                  );
                }),
              onRowUpdate: (newRow) =>
                new Promise((resolve, reject) => {
                  dispatch(
                    updateUser({
                      userId: newRow.id,
                      userRequestBody: createUserRequestBody(newRow),
                      onStopLoading: resolve,
                    })
                  );
                }),
            }}
          />
        </div>
      </AppDrawerMenuLayout>
    </>
  );
};
