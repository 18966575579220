import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { AppDrawerMenu } from "./appDrawerMenu";

const useStyles = makeStyles<Theme, boolean>((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: (open) => (open ? "calc(100vw - 240px)" : "100vw"),
    marginLeft: (open) => (open ? "240px" : "0px"),
    transition: (open) =>
      open
        ? `all  ${theme.transitions.duration.leavingScreen - 100}ms`
        : `all ${theme.transitions.duration.enteringScreen + 100}ms`,
  },
  menu: {
    position: "fixed",
    top: 0,
  },
}));

interface AppDrawerMenuLayoutProps {
  children: React.ReactNode;
  open?: boolean;
}

export const AppDrawerMenuLayout: React.FunctionComponent<AppDrawerMenuLayoutProps> = ({
  children,
  open = true,
}) => {
  const classes = useStyles(open);

  return (
    <div className={classes.root}>
      <AppDrawerMenu className={classes.menu} open={open} />
      <div className={classes.content}>{children}</div>
    </div>
  );
};
