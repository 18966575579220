import { AxiosResponse } from "axios";
import instance from "../../axios";
import { IUser } from "../backendApi/types/user";
import { IUserRequestBody } from "../backendApi/types/requestBodies";

export async function getUsers(): Promise<AxiosResponse<IUser[]>> {
  return await instance.get("users");
}

export async function postUser(
  userRequestBody: IUserRequestBody
): Promise<AxiosResponse<IUser>> {
  return await instance.post("users", userRequestBody);
}

export async function deleteUser(id: number): Promise<AxiosResponse<void>> {
  return await instance.delete(`users/${id}`);
}

export async function putUser(id: number, userRequestBody: IUserRequestBody) {
  return await instance.put(`users/${id}`, userRequestBody);
}
